// @ts-nocheck
export function getConnectionType() {
    // @ts-ignore
    if (!global.window) {
        return "";
    }
    const connection =
        window.navigator.connection ||
        // @ts-expect-error FixMe
        window.navigator.mozConnection ||
        // @ts-expect-error FixMe
        window.navigator.webkitConnection;
    const isConnected = navigator.onLine;

    if (!connection) {
        return isConnected ? "other" : "none";
    }

    if (!connection.type || connection.type === "mixed") {
        return isConnected ? "other" : "unknown";
    }

    return connection.type;
}
