import {removeFantasyCookie} from "./cookie";
import {getWebBaseUrl} from "./url";

export function getGigyaAccount() {
    return new Promise<any>((resolve, reject) => {
        if (!window?.gigya?.accounts?.getAccountInfo) {
            reject(false);
            return;
        }

        const start = Date.now();
        window.gigya.accounts.getAccountInfo({
            include: "data,profile,loginIDs",
            callback: (response) => {
                console.log(
                    "gigya.accounts.getAccountInfo",
                    `${(Date.now() - start) / 1000} s`
                );
                if (response.status === "OK") {
                    resolve(response);
                } else {
                    reject(response.errorMessage);
                }
            },
        });
    });
}

export async function submitGigyaLoginForm(gigyaAccount: {
    UID: string;
    UIDSignature: string;
    signatureTimestamp: string;
}) {
    if (document.location.pathname === "/auth/gigyalogin") {
        return;
    }
    const body = new URLSearchParams();
    body.append("gigya_UID", gigyaAccount.UID);
    body.append("gigya_signature", gigyaAccount.UIDSignature);
    body.append("gigya_signature_timestamp", gigyaAccount.signatureTimestamp);
    const host =
        document.location.hostname.indexOf("fantasy.nfl.com") > -1
            ? ""
            : getWebBaseUrl(document.location.hostname);
    try {
        await fetch(`${host}/auth/gigyalogin`, {
            body,
            method: "POST",
            redirect: "manual",
        });
        // fantasy cookie is set, redirect to returnTo
        const params = new URLSearchParams(document.location.search);
        const returnTo = params.get("returnTo");
        if (returnTo) {
            window.location.assign(returnTo);
        }
    } catch (e) {
        console.error(e);
    }
}

export function gigyaSignIn() {
    window.gigya.sso.login({
        authFlow: "redirect",
        context: {
            apiKey: window.gigya.apiKey,
        },
    });
}

export function gigyaSignOut() {
    window.gigya.accounts.logout({
        callback(response) {
            if (response.errorCode === 0) {
                removeFantasyCookie();
                window.location.assign("/");
            }
        },
    });
}
